import { Component, OnInit } from "@angular/core";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  ActivatedRoute,
  ActivationEnd,
  Router,
  RouterEvent,
  RouterOutlet,
} from "@angular/router";
import { StorageService } from "./_services/storage.service";
import { AppConfigService } from "./_services/app-config.service";
import { StepperService } from "./_services/step.service";
import { filter } from "rxjs/operators";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  ActiveTitle: string = "";
  PathData: object = AppConfigService.settings.stepStatic;
  BackPath: string = "";
  colWidth: string = "col-lg-5 col-md-6 ";
  BigTitle: string = "";
  BigPara: string = "";
  StaticSteps: object = {};
  CurrentStep: number = 1;
  showLoader: Boolean = false;
  StepperLabel = {};
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private ss: StepperService
  ) {
    router.events.subscribe((routerEvent: RouterEvent) => {
      this.checkRouteChange(routerEvent);
    });
  }
  ngOnInit() {
    var Pathdatas = this.PathData;
    var pathObject = Object.keys(Pathdatas).slice(0, -1);
    var defaultlbl = {};
    pathObject.forEach((items) => {
      var slbl = "Step" + this.PathData[items]["Step"];
      defaultlbl[slbl] = this.PathData[items]["StepLbl"];
    });

    this.ss.setLabels(defaultlbl);

    this.ss.StepprtSubject.subscribe((lbl) => {
      this.StepperLabel = lbl;
    });

    this.StaticSteps = pathObject;
    this.router.events
      .pipe(filter((x) => x instanceof ActivationEnd))
      .subscribe((event: ActivationEnd) => {
        var routerpath = event.snapshot["routeConfig"]["path"];

        var pathdata = this.PathData[routerpath];
        if (pathdata["locatype"]) {
          this.storageService.secureStorage.removeItem(pathdata["locatype"]);
        }
        if (pathdata["Title"]) {
          this.ActiveTitle = pathdata["Title"];
        } else {
          this.ActiveTitle = "";
        }
        if (pathdata["BackPath"]) {
          var location =
            this.storageService.secureStorage.getItem("locationSelected");
          var ClinicName = location["ClinicName"];
          ClinicName = ClinicName.replace(/\s+/g, "-").toLowerCase();
          var backpath = pathdata["BackPath"];
          backpath = backpath.replace(/:clinic/g, ClinicName);
          this.BackPath = backpath;
        } else {
          this.BackPath = "";
        }
        if (pathdata["colWidth"]) {
          this.colWidth = pathdata["colWidth"];
        } else {
          this.colWidth = "col-lg-5 col-md-6";
        }

        if (pathdata["BigTitle"]) {
          this.BigTitle = pathdata["BigTitle"];
        } else {
          this.BigTitle = "";
        }
        if (pathdata["BigPara"]) {
          this.BigPara = pathdata["BigPara"];
        } else {
          this.BigPara = "";
        }
        if (pathdata["Step"]) {
          this.CurrentStep = pathdata["Step"];
        } else {
          this.CurrentStep = 1;
        }
        this.StepperLabel["Step" + this.CurrentStep] = pathdata["StepLbl"];
        this.ss.setLabels(this.StepperLabel);
      });
  }
  checkRouteChange(routerEvent: RouterEvent) {
    if (routerEvent instanceof NavigationStart) {
      this.showLoader = true;
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.showLoader = false;
    }
  }
}
