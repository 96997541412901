import { HttpClient, } from "@angular/common/http";
import { Observable, } from "rxjs";
import { AppConfigService } from "./app-config.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PractitionerService {
    constructor(http) {
        this.http = http;
        this.apiurl = AppConfigService.settings.apiServer.apiurl;
    }
    getSechudel(ckey, prkey, atkey, date) {
        var query = "";
        query += "?pkey=" + prkey;
        query += "&atkey=" + atkey;
        if (date != "") {
            query += "&date=" + date;
        }
        var urls = this.apiurl + "appointment/bookingavaltime/" + ckey + query;
        return this.http.get(urls);
    }
    getAvlDate(ckey, prkey, atkey, month) {
        var query = "";
        query += "?pkey=" + prkey;
        query += "&atkey=" + atkey;
        if (month != "") {
            query += "&month=" + month;
        }
        var urls = this.apiurl + "appointment/bookingavaldate/" + ckey + query;
        return this.http.get(urls);
    }
}
PractitionerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PractitionerService_Factory() { return new PractitionerService(i0.ɵɵinject(i1.HttpClient)); }, token: PractitionerService, providedIn: "root" });
