<form [formGroup]="userform">
  <div class="row">
    <div class="col form-group mb-3">
      <input type="text" class="form-control" placeholder="First name" [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }" formControlName="FirstName">
    </div>
    <div class="col form-group mb-3">
      <input type="text" class="form-control" placeholder="Last name" formControlName="LastName" [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }">
    </div>
  </div>
	 <div class="row">
    <div class="col form-group mb-3">
      <input type="text" class="form-control" placeholder="Mobile phone" formControlName="PhoneNumber" [ngClass]="{ 'is-invalid': submitted && f.PhoneNumber.errors }">
    </div>
    <div class="col form-group mb-3">
      <input type="text" class="form-control" placeholder="Email address" formControlName="EmailId" [ngClass]="{ 'is-invalid': submitted && f.EmailId.errors }">
    </div>
  </div>
	  <div class="form-group mb-3">
   
    <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Comments (optional)" formControlName="Comment" rows="3" [ngClass]="{ 'is-invalid': submitted && f.Comment.errors }"></textarea>
  </div>
	<div class="d-none">
	<button type="submit" ></button>
	</div>
</form>