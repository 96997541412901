import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-calender",
  templateUrl: "./calender.component.html",
  styleUrls: ["./calender.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CalenderComponent implements OnInit {
  today: Date = new Date();
  months: object = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  Weeks: Array<string> = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  NumWeeks: object;
  WeekStarts:number=1;
  currentMonth: number;
  presentMonth: number;
  currentYear: number;
  CurrentDate: number;
  CalenderTitle: string;
  Days: number;
  DaysRag: object;
  firstDay: number;
  cellCount: number = 0;
  CalenderList: object[][] = [];
  /*SelectedDate: Date;*/
  SelectedTime: string;
  @Output() DateSelected = new EventEmitter<any>(true);
  @Output() MonthChanged = new EventEmitter<any>(true);
  @Output() TimeSelected = new EventEmitter<any>(true);
  @Input() AvalDates: object[] = [];
  @Input() SelectedDate: Date;
  CurrentMonthDate:Date=new Date();
  CurrentAval: object[] = [];
  MonthChang: boolean = false;
  constructor() {}

  ngOnInit() {
    this.MonthChang = false;
    var weekcount=0;
    
    this.today.setHours(0, 0, 0, 0);
    /*this.SelectedDate = null;*/
    this.NumWeeks = [...Array(6).keys()];
    this.presentMonth = this.today.getMonth();
    this.currentMonth = this.today.getMonth();
    this.currentYear = this.today.getFullYear();
    this.CurrentDate = this.today.getDate();

    this.CalenderTitle =
      this.months[this.currentMonth] + " " + this.currentYear;

    this.Days = this.daysInMonth(this.currentMonth, this.currentYear);
    this.DaysRag = [...Array(this.Days).keys()];
    this.firstDay = this.GetFirstDay(this.currentMonth, this.currentYear);
    if (this.AvalDates) {
      if (this.AvalDates.length != 0) {
        this.CurrentAval = this.AvalDates;
      }
    }
this.CurrentMonthDate=new Date();
    this.showCalendar();
  }
  ngOnChanges(changes: SimpleChanges) {
     
    if (this.MonthChang) {
      if (changes.AvalDates) {
        this.CurrentAval = changes.AvalDates.currentValue;
      } else {
      }
      this.CurrentMonthDate.setMonth(this.currentMonth);

      this.showCalendar( );
    }
  }
  splitArray(array, chunk) {
    var i,
      j,
      temparray = [];
    for (i = 0, j = array.length; i < j; i += chunk) {
      temparray.push(array.slice(i, i + chunk));
    }
    return temparray;
  }
  next(event) {
    event.preventDefault();
    this.currentYear =
      this.currentMonth === 11 ? this.currentYear + 1 : this.currentYear;
    this.currentMonth = (this.currentMonth + 1) % 12;
    
    this.CalenderTitle =
      this.months[this.currentMonth] + " " + this.currentYear;

    this.Days = this.daysInMonth(this.currentMonth, this.currentYear);
    this.DaysRag = [...Array(this.Days).keys()];
    this.firstDay = this.GetFirstDay(this.currentMonth, this.currentYear);
     
    /*this.CalenderList = [];*/

    this.MonthChanged.emit(this.currentMonth + 1);
    this.MonthChang = true;
  }

  previous(event) {
    event.preventDefault();
    this.currentYear =
      this.currentMonth === 0 ? this.currentYear - 1 : this.currentYear;
    this.currentMonth = this.currentMonth === 0 ? 11 : this.currentMonth - 1;
    this.CalenderTitle =
      this.months[this.currentMonth] + " " + this.currentYear;

    this.Days = this.daysInMonth(this.currentMonth, this.currentYear);
    this.DaysRag = [...Array(this.Days).keys()];
    this.firstDay = this.GetFirstDay(this.currentMonth, this.currentYear);
   /* this.CalenderList = [];*/
    /*this.showCalendar();*/
    this.MonthChanged.emit(this.currentMonth + 1);
    this.MonthChang = true;
  }
  f_tcalResetTime (d_date) {
    d_date.setMilliseconds(0);
    d_date.setSeconds(0);
    d_date.setMinutes(0);
    d_date.setHours(12);
    return d_date;
}
  showCalendar() {
   var d_today = this.f_tcalResetTime(new Date());
   const zeroPad = (num, places) => String(num).padStart(places, "0");
   var d_selected=this.SelectedDate ;
 var  d_date =this.CurrentMonthDate;
   var d_firstDay = new Date(d_date);
   d_firstDay.setDate(1);
  d_firstDay.setDate(1 - (7 + d_firstDay.getDay() - this.WeekStarts) % 7);
  var n_date, n_month, d_current = new Date(d_firstDay);
  
  var weekdaycount=0;
  this.CalenderList = [];
  while (d_current.getMonth() == d_date.getMonth() ||
                d_current.getMonth() == d_firstDay.getMonth()) {
                  this.CalenderList[weekdaycount] = [];
                  var weeoklist = [];
                  for (var n_wday = 0; n_wday < 7; n_wday++) { 
                    n_date = d_current.getDate();
                    n_month = d_current.getMonth();
                     
                  var strindate=  
                  zeroPad(d_current.getFullYear(), 2) +
                  "-" +
                  zeroPad(d_current.getMonth()+ 1, 2) +
                  "-" +
                  zeroPad(d_current.getDate(), 2);
                  var d_d = new Date(d_current);
                  var isavalDate = this.CurrentAval.filter((item) => {
                    return parseInt(item["DayValue"])  ==  d_d.getDay();
                  });
                  
                  weeoklist.push({
                    day: d_d.getDay(),
                    dateNum:d_d.getDate(),
                    date: d_d, 
                    cmth:d_d.getMonth(),
                    isaval: (isavalDate.length!=0)?Number(isavalDate[0]["DayAvailable"]):0,
                    aval: (isavalDate.length!=0)?isavalDate[0]["DayAvailable"]:0,
                    dayval:(isavalDate.length!=0)?isavalDate[0]["DayValue"]:0,
                  });
                    d_current.setDate(++n_date);
                  } 
                  this.CalenderList[weekdaycount] = weeoklist;
                  weekdaycount++;
                }
                


  }

  daysInMonth(iMonth, iYear) {
    return 32 - new Date(iYear, iMonth, 32).getDate();
  }
  GetFirstDay(month, year) {
    return new Date(year, month).getDay();
  }
  displayPTime(event, Cdate, calBody) {}
  displayTime(event, Cdate, calBody) {
    this.SelectedDate = Cdate["date"];

    this.DateSelected.emit(this.SelectedDate);
    event.target.classList.add("active");
  }
}
