<div class="card  ">
  <div class="card-header bg-white ">
    <ul class="nav align-items-center justify-content-between"> 
      <li class="nav-item text-dark">
        <h5 class="mb-0 fs-16">{{ CalenderTitle }}</h5>
      </li> 
      <li class="nav-item d-flex justify-content-center align-items-center">
        <a class="nav-link text-dark lh-1 py-1" href="#" (click)="previous($event)" [ngClass]="{
          disabled:
            currentMonth <= today.getMonth() &&
            currentYear <= today.getFullYear()
        }"><i class="las la-angle-left fs-20"></i></a>
        <a class="nav-link text-dark lh-1 py-1" href="#" (click)="next($event)"><i
            class="las la-angle-right fs-20"></i></a>
      </li>
    </ul>
  </div>

  <div class="card-body p-2">
    <table class="table table-borderless table-sm mb-0 caltable">
      <thead>
        <tr class="fs-13">
          <th *ngFor="let weekname of Weeks; let dd = index" class="text-center week-{{ dd }}">
            <div class="day_name"> {{ Weeks[(WeekStarts+dd)%7] }}</div>
          </th>
        </tr>
      </thead>
      <tbody #calBody>
        <tr *ngFor="let wday of CalenderList">
          <td *ngFor="let dayDate of wday; let dd = index" class="week-{{ dd }}">
            <div class="text-center d-inline-block datebox">
             <div *ngIf="dayDate['date'].getMonth() == CurrentMonthDate.getMonth()">
              <div *ngIf="dd ==6 " class="previous bg-secondary bg-opacity-10 date-content">
              <span class="data_num">{{ dayDate["dateNum"] }}</span>
              </div>
              <div *ngIf="
dd != 6 &&
dayDate['date'].getTime() < today.getTime()
" class="notaval  bg-secondary bg-opacity-10 date-content">
<span class="data_num">{{ dayDate["dateNum"] }}</span>
</div>
<div *ngIf="
dd != 6 &&
dayDate['date'].getTime()>= today.getTime()  && dayDate['isaval']==0
" class="notaval  bg-secondary bg-opacity-10 date-content">
<span class="data_num">{{ dayDate["dateNum"] }}</span>
</div>
              <a href="javascript:void(0);"  *ngIf="dd!=6 &&
              dayDate['date'].getTime() >= today.getTime() && dayDate['isaval']!=0 "  (click)="displayTime($event, dayDate, calBody)" class="date-content clickDate text-dark" [ngClass]="{
                  active:
                    SelectedDate &&
                    dayDate['date'].getTime() == SelectedDate.getTime(),
                    future:dayDate['date'].getMonth() > CurrentMonthDate.getMonth() ,
                  current:
                    today.getDate() + '-' + today.getMonth() ==
                    dayDate['date'].getDate() + '-' + dayDate['date'].getMonth(),
                    'bg-secondary bg-opacity-10':dd==6
                }"><span class="data_num">{{ dayDate["dateNum"] }} </span>
              </a>
             </div>
             
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>