import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AppConfigService } from "../_services/app-config.service";
import { StorageService } from "../_services/storage.service";
@Injectable()
export class Token implements HttpInterceptor {
  constructor(private storageService: StorageService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.headers.get("skip")) {
      var LocationSelected =
        this.storageService.secureStorage.getItem("locationSelected");
      var NewUserData = JSON.stringify({
        pk: AppConfigService.settings.clinic.pk,
        name: AppConfigService.settings.clinic.name,
        OnlineBookingType: AppConfigService.settings.OnlineBookingType,
        IsOnlinePayment: AppConfigService.settings.IsOnlinePayment,
      });

      var newUsertoken = btoa(NewUserData);
      const modified = request.clone({
        params: new HttpParams().set("token", newUsertoken),
      });

      return next.handle(modified);
    } else {
      return next.handle(request);
    }
  }
}
