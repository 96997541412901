import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import xml2js from 'xml2js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  userform: FormGroup;
  submitted: boolean = false;
  public xmlItems: any;
  constructor(private formBuilder: FormBuilder, private _http: HttpClient) { }

  ngOnInit() {


    /*this._http.get('/assets/clinic.xml',
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'text/xml')
          .append('Access-Control-Allow-Methods', 'GET')
          .append('Access-Control-Allow-Origin', '*')
          .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"),
        responseType: 'text'
      })
      .subscribe((data) => {

      });*/
    this.userform = this.formBuilder.group({
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      PhoneNumber: ['', Validators.required],
      EmailId: ['', [Validators.required, Validators.email]],
      Comment: '',
    });
  }
  getSubdomain() {
    const domain = window.location.hostname;

  }
  get f() {
    return this.userform.controls;
  }
  submitform() {
    this.submitted = true;
    if (!this.userform.invalid) {


      var postpara = {};
      var formData: any = new FormData();
      Object.keys(this.userform.controls).forEach(key => {
        postpara[key] = this.f[key].value;
        formData.append(key, this.f[key].value);
      });

      return postpara;
    }
    return false;

  }
}
